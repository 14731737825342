import React, { useState } from "react";
import LayoutComponent from "../components/LayoutComponent/LayoutComponent";
import SeperatorComponent from "../components/SeperatorComponent/SeperatorComponent";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { graphql, PageProps } from "gatsby";
import "../styles/team.styles.scss";
import { Page } from "../components/MenuComponent/MenuComponent.types";
import { useTranslation } from "gatsby-plugin-react-i18next";
import TeamMemberComponent from "../components/TeamMemberComponent/TeamMemberComponent";
import { RowInfo } from "../components/TeamMemberComponent/TeamMemberComponent.types";

const TeamPage = ({ data }: PageProps) => {
  const { t } = useTranslation();
  const [teamRowInfo, setTeamRowInfo] = useState<RowInfo[]>([]);

  const teamMember: {
    name: string;
    position: string;
    description: string;
    image: string;
    speech: string;
  }[] = t("team.members", { returnObjects: true });

  /**
   * Helper to fetch correct image data for processing
   * @param imageName name to filter from array
   * @returns found image data or null
   */
  const getImageDataForMember = (imageName: string): IGatsbyImageData => {
    let imageList: any[] = (data as any).allFile.edges;
    let filteredImages: any[] = imageList.filter(
      (item) => item.node.base === imageName
    );
    if (filteredImages.length === 1) {
      return filteredImages[0].node.childImageSharp
        .gatsbyImageData as IGatsbyImageData;
    }
    return null!;
  };

  /**
   * Helper to update and close, if needed the open team member
   * @param rowInfo local data to update or add
   */
  const addOrUpdateRowInfo = (rowInfo: RowInfo): void => {
    let foundIndex: number = teamRowInfo.findIndex(
      (info) => info.index === rowInfo.index
    );
    if (foundIndex === -1) teamRowInfo.push(rowInfo);
    else teamRowInfo[foundIndex] = rowInfo;

    // close needed members
    if (teamRowInfo.length === teamMember.length && rowInfo.open) {
      let foundItemsToClose = teamRowInfo.filter(
        (items) => items.row === rowInfo.row && items.index !== rowInfo.index
      );
      for (let index = 0; index < foundItemsToClose.length; index++) {
        foundItemsToClose[index].open = false;
      }
    }

    setTeamRowInfo([...teamRowInfo]);
  };

  return (
    <LayoutComponent page={Page.ABOUT_US} seo={{ title: t("team.title") }}>
      <div className="padding-wrapper-60">
        <h2 className="text-wrapper--h2 center-text">{t("team.title")}</h2>
        <SeperatorComponent />
        <div className="text-wrapper--paragraph center-text">
          {t("team.subTitle")}
        </div>
        <div className="team-wrapper">
          {teamMember.map((member, memberIndex) => (
            <TeamMemberComponent
              imageData={getImageDataForMember(member.image)}
              member={member}
              index={memberIndex}
              onRowInfoUpdate={addOrUpdateRowInfo}
              key={`team-member-entry-${memberIndex}`}
              open={!!teamRowInfo[memberIndex]?.open}
            />
          ))}
          <div className="current-text-wrapper"></div>
        </div>
      </div>
    </LayoutComponent>
  );
};

export default TeamPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allFile(
      filter: {
        extension: { regex: "/(jpg)|(png)|(jpeg)/" }
        relativeDirectory: { eq: "team" }
      }
    ) {
      edges {
        node {
          base
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  }
`;
