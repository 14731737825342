import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { Collapse } from "react-collapse";
import { useWindowSize } from "../../utils/useWindowSize";
import SeperatorComponent from "../SeperatorComponent/SeperatorComponent";
import "./TeamMemberComponent.style.scss";
import { RowInfo, TeamMemberComponentProps } from "./TeamMemberComponent.types";
import { cutTextByLength } from "../../utils/General.utils";
import { useTranslation } from "react-i18next";

const TeamMemberComponent: React.FC<TeamMemberComponentProps> = ({
  imageData,
  member,
  index,
  onRowInfoUpdate,
  open,
}) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const internalId: string = `meber-entry-class-id-${index}`;
  const [curRowInfo, setCurRowInfo] = useState<RowInfo>({
    amount: 0,
    row: 0,
    position: 0,
    open: !!open,
    index: index,
  });

  /**
   * helper to return newly updated row data
   */
  useEffect(() => {
    onRowInfoUpdate?.(curRowInfo);
  }, [curRowInfo]);

  /**
   * this useeffects updates the open
   * state in current tem member component
   */
  useEffect(() => {
    setCurRowInfo({ ...curRowInfo, open: !!open });
  }, [open]);

  /**
   * this useEffect calculates the correct
   * amount of margin left which is currently needed
   */
  useEffect(() => {
    let calculatedLeftMargin: number = 0;
    let localRowInfo: RowInfo = { ...curRowInfo };
    let fixedAmount: number = 0;
    switch (true) {
      case width >= 1320:
        fixedAmount = 4;
        break;
      case width >= 1020:
        fixedAmount = 3;
        break;
      case width >= 720:
        fixedAmount = 2;
        break;
      default:
        fixedAmount = 1;
        break;
    }
    // margin for text
    calculatedLeftMargin = 300 * (index % fixedAmount);
    // row info
    localRowInfo.amount = fixedAmount;
    localRowInfo.row = Math.floor(index / fixedAmount);
    localRowInfo.position = fixedAmount;
    // save the states
    if (localRowInfo !== curRowInfo) setCurRowInfo(localRowInfo);
    setCurrentMarginLeftToCollapse(calculatedLeftMargin);
  }, [width]);

  /**
   * Helper to set a sepecific margin left to an element
   * @param marginLeft number of margin left to use
   */
  const setCurrentMarginLeftToCollapse = (marginLeft: number): void => {
    let foundDivElement = document.getElementsByClassName(internalId);
    if (foundDivElement.length !== 1) return;
    (
      foundDivElement[0] as HTMLDivElement
    ).style.marginLeft = `-${marginLeft}px`;
  };

  return (
    <div className="team-wrapper--entry" key={`team-member-${index}`}>
      <div
        onClick={() => setCurRowInfo({ ...curRowInfo, open: !curRowInfo.open })}
      >
        <div className="team-member-image-wrapper">
          <GatsbyImage
            className="center-items"
            image={imageData}
            alt={member.name}
          />
          {curRowInfo.open && <div className="arrow" />}
        </div>
        <div className="name">{member.name}</div>
        <div className={"position"}>{member.position}</div>
      </div>

      <div
        className="content"
        onClick={() => setCurRowInfo({ ...curRowInfo, open: !curRowInfo.open })}
      >
        <div
          className="short-text-preview"
          style={{
            color: curRowInfo.open ? "transparent" : "initial",
          }}
        >
          {cutTextByLength(member.description, null!, 120)}
          <div className="find-out-more">{t("team.more")}</div>
        </div>
        <Collapse
          theme={{
            collapse: `ReactCollapse--collapse ${internalId}`,
          }}
          isOpened={curRowInfo.open}
        >
          <div className="text--content-wrapper">
            <div className="text">
              <div
                dangerouslySetInnerHTML={{
                  __html: member.description,
                }}
              />
              <SeperatorComponent height={20} />
              <div>{`${member.name}: "${member.speech}"`}</div>
            </div>
          </div>
        </Collapse>
      </div>

      <div className="expand--content" />
    </div>
  );
};

export default TeamMemberComponent;
